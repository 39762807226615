function resource(api, data = [], config = {}, argument, autoload = true) {
  var resource = {
    _api: api,
    _autoload: autoload,
    _argument: argument,
    _lastUpdated: null,
    loading: false,
    loaded: false,
    error: null,
    success: false,
    key: 'data',
    ...config,
    _originalData: data,
    data: data,
    reset() {
      this.loading = false;
      this.loaded = false;
      this.error = null;
      this.success = false;
      this.data = this._originalData;
    },
  };

  return resource;
}

export default {
  namespaced: true,
  state: {
    admin: {
      // companies: resource('admin.companies.all'),
      roles: resource('admin.roles.all'),
    },
    corporate: {
      activities: resource('corporate.activities.recent'),
      admins: resource('corporate.admins.all'),
      staff: resource('corporate.staff.all'),
      groups: resource('corporate.groups.all', [], { key: 'groups' }),
      cards: resource('corporate.cards.all'),
      wallet: resource('corporate.wallet.get', {}, {}, function () {
        return this.user.company_id;
      }),
      plan: resource(
        'corporate.subscription.getActivePlan',
        {},
        {},
        function () {
          return this.user.company_id;
        }
      ),
      policies: resource('corporate.expensePolicies.all', [], {}, function () {
        return this.user.company_id;
      }),
    },
    customer: {
      loans: resource('personal.loans.all', []),
      repayments: resource('personal.repayments.upcoming', [], {
        key: 'repayments',
      }),
      repaymentCards: resource(
        'personal.repaymentCards.all',
        [],
        { key: 'card' },
        function () {
          return this.user?.id;
        }
      ),
      personalCards: resource('personal.cards.personal.all', []),
    },
  },
  mutations: {
    clear(state, { mode, resourceName }) {
      const resource = state[mode]?.[resourceName];
      resource.reset();
    },
    error(state, { mode, error, resourceName }) {
      var resource = state[mode]?.[resourceName];
      resource.error = error;
    },
    loading(state, { mode, status = true, resourceName }) {
      var resource = state[mode]?.[resourceName];
      resource.loading = status;
    },
    save(state, { mode, data = true, resourceName }) {
      var resource = state[mode]?.[resourceName];
      resource.data = data;
      resource._lastUpdated = new Date();
    },
  },
  actions: {
    clear({ commit }, { mode, resourceName }) {
      commit('clear', { mode, resourceName });
    },
    clearAll({ state, dispatch }) {
      for (const mode in state) {
        if (Object.hasOwnProperty.call(state, mode)) {
          const resources = state[mode];

          if (!resources) {
            throw new Error(`No resources in ${resources}`);
          }

          for (let resourceName in resources) {
            if (Object.hasOwnProperty.call(resources, resourceName)) {
              dispatch('clear', { resourceName, mode });
            }
          }
        }
      }
    },
    fetchAll({ state, dispatch }, mode) {
      if (!mode) {
        console.warn('No Mode Set For Resources');
        return false;
      }

      const resources = state[mode];

      if (!resources) {
        throw new Error(`No resources in ${mode}`);
      }

      for (let resourceName in resources) {
        dispatch('fetch', {
          resourceName,
          mode,
          autoload: true,
          forced: false,
        });
      }
    },
    async fetch(
      { commit, state },
      { mode, resourceName, autoload = false, forced = true }
    ) {
      const request = state[mode][resourceName];

      if (!request) {
        throw new Error(`Unknown Resource ${resourceName}`);
      }

      if (autoload && !request._autoload) {
        return false;
      }

      if (!forced) {
        const _lastUpdated = request._lastUpdated;
        if (_lastUpdated) {
          if (new Date().getTime() - _lastUpdated.getTime() <= 300000) {
            return false;
          }
        }
      }

      commit('loading', { mode, resourceName, status: true });

      var args = [request._api];

      if (request._argument) {
        args.push(request._argument.apply(window.App));
      }

      args.push({
        error: (error) => {
          console.log(error);
          commit('error', { mode, resourceName, error });
        },
      });

      var response = await window.App.sendRequest(...args); // Remove destructuring

      // Check if response is not null and contains data property

      if (response?.data) {
        var data;
        if (request.key) {
          data = response.data[request.key];
        } else if (response.data.data) {
          data = response.data.data;
        } else {
          data = response.data;
        }

        commit('save', { mode, resourceName, data });
      } else {
        // Handle cases where response is undefined or does not have data property
        console.error('Error: response or response.data is undefined or null');
      }

      commit('loading', { mode, resourceName, status: false });
    },
  },
};
